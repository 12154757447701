<template>
    <div :class="loadTarget">
        <slot></slot>
    </div>
</template>

<script>
    import Anim from './Anim.vue';

    export default {
        extends: Anim
    }
</script>