<template>
    <div :class="loadTarget" ref="anim">
        <slot></slot>
    </div>
</template>

<script>
    import ScrollAnim from './ScrollAnim.vue';

    export default {
        extends: ScrollAnim,

        mounted(){
            this.anchor = this.$refs['anim'];
            window.addEventListener("load", this.requestLocation);
        }
    }
</script>