<template>
    <div class="page-container">
        <navbar />
        <div id="anchor">&#8593;</div>
        <slot></slot>
        <footer-ad />
    </div>
</template>

<script>
    import '@/assets/css/main_style.css';
    import '@/assets/css/desktop_style.css';
    import wait from '@/assets/js/wait.js';

    import FooterAd from './FooterAd.vue';
    import Navbar from './Navbar.vue';

    export default {
        components: {
            FooterAd,
            Navbar
        },

        data(){
            return {
                anchor: undefined,
                anchorDisplayed: false
            }
        },

        methods: {
            updateAnchor(){
                if(!this.anchorDisplayed && window.scrollY >= 500){
                    this.anchorDisplayed = true;
                    this.anchor.style.display = "block";
                    wait.millis(25, () => this.anchor.style.opacity = "1.0");
                } else if(this.anchorDisplayed && window.scrollY < 500){
                    this.anchorDisplayed = false;
                    this.anchor.style.opacity = "0.0";
                    wait.millis(400, () => this.anchor.style.display = "none");
                }
            }
        },

        mounted(){
            this.anchor = document.getElementById("anchor");
            this.anchor.onclick = () => window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });

            window.addEventListener("scroll", this.updateAnchor);
        },

        beforeUnmount(){
            window.removeEventListener("scroll", this.updateAnchor);
        }
    }
</script>