<template>
    <div id="contact_info">
        <scroll-class-anim target="contact_row" :range="range" id="contact_left">
            <img src="@/assets/images/message_icon_black.png" alt="Contact Us" />
            <a href="mailto:priveexcavating@gmail.com"><p>priveexcavating@gmail.com</p></a>
        </scroll-class-anim>
        <scroll-class-anim target="contact_row" :range="range">
            <img src="@/assets/images/phone_icon_black.png" alt="Our Phone Number" />
            <p>604-751-5613</p>
        </scroll-class-anim>
        <scroll-class-anim target="contact_row" :range="range" id="contact_right">
            <img src="@/assets/images/location_icon_black.png" alt="Our Location" />
            <p>Along the Fraser Valley from Vancouver to Chilliwack.</p>
        </scroll-class-anim>
        
        
        <a href="https://www.worksafebc.com/en" target="_blank">
            <img src="@/assets/images/work_safe.jpg" alt="Work Safe BC" />
        </a>
    </div>
</template>

<script>
    import ScrollClassAnim from '../anim/ScrollClassAnim.vue';

    export default {
        components: {
            ScrollClassAnim
        },

        props: {
            range: ""
        }
    }
</script>

<style>
    
</style>