<script>
    import Anim from './Anim.vue';

    export default {
        extends: Anim,

        methods: {
            toggleHover(){
                this.toggleNative();
                this.$emit('toggle-hovered');
            },

            toggleExit(){
                this.toggleNative();
                this.$emit('toggle-exited');
            }
        }
    }
</script>