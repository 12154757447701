<template>
    <div :id="loadTarget" @click="toggle">
        <slot></slot>
    </div>
</template>

<script>
    import Anim from '@/components/anim/Anim.vue';

    export default {
        extends: Anim
    }
</script>