<script>
    import Anim from './Anim.vue';

    export default {
        extends: Anim,

        props: {
            range: ""
        },

        data(){
            return {
                detectionMode: 0,
                running: true,
                anchor: undefined
            }
        },

        methods: {
            requestLocation(){
                if(!this.toggled && window.scrollY >= this.anchor.getBoundingClientRect().y - this.range){
                    console.log(this.target + " toggled");
                    this.toggle();
                    this.running = false;
                } else if(this.toggled)
                    window.removeEventListener("scroll", this.requestLocation);
            }
        },

        mounted(){
            window.addEventListener("scroll", this.requestLocation);
        },

        beforeUnmount(){
            if(this.running)
                window.removeEventListener("scroll", this.requestLocation);
        }
    }
</script>