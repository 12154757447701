<script>
    export default {
        data(){
            return {
                popupRange: 0,
                secondPopupRange: 0,
                thirdPopupRange: 0
            }
        },

        methods: {
            identifyPopupRange(){
                //this.popupRange = popup_range_identifier.getRange();
                let pr, pr2 = 0, pr3 = 0;
                if(window.innerWidth <= window.innerHeight){
                    if(window.visualViewport.width < 732){
                        pr = 550;
                        pr2 = -1000;
                        pr3 = -1300;
                    } else
                        pr = 1200;
                } else {
                    pr = 900;
                    pr2 = 0;
                    pr3 = -350;
                }
                this.popupRange = pr;
                this.secondPopupRange = pr2;
                this.thirdPopupRange = pr3;
            }
        },

        mounted(){
            this.identifyPopupRange();
            window.addEventListener("resize", this.identifyPopupRange);
        },

        beforeUnmount(){
            window.removeEventListener("resize", this.identifyPopupRange);
        }
    }
</script>