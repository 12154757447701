<template>
    <div :id="loadTarget" @mouseover="toggleHover" @mouseout="toggleExit">
        <slot></slot>
    </div>
</template>

<script>
    import HoverAnim from './HoverAnim.vue';

    export default {
        extends: HoverAnim
    }
</script>