<script>
    export default {
        props: {
            target: "",
            toggleOnRender: ""
        },

        data(){
            return {
                toggled: false
            }
        },

        computed: {
            loadTarget(){
                return this.target + (this.toggled ? "_anim" : "");
            }
        },

        methods: {
            toggleNative(){
                this.toggled = !this.toggled;
            },

            toggle(){
                this.toggleNative();
                this.$emit('toggle-switch');
            },

            isToggled(){
                return this.toggled;
            }
        },

        mounted(){
            if(this.toggleOnRender == "true")
                this.toggled = true;
        }
    }
</script>