<template>
    <div id="nav-container">
        <nav>
			<click-id-anim target="ham_btn" @toggle-switch="toggleNav" ref="ham_btn">
				<span id="ham_slot1" class="ham_slot"></span>
				<span id="ham_slot2" class="ham_slot"></span>
				<span id="ham_slot3" class="ham_slot"></span>
			</click-id-anim>
			
			<div id="logo_info_side">
				<router-link to="/">
					<img src="@/assets/images/logo.png" alt="Prive Excavating Ltd." id="logo_info" />
					<img src="@/assets/images/title1_test.png" alt="Prive Excavating Ltd." id="logo_info_top" />
					<img src="@/assets/images/title1.png" alt="Prive Excavating Ltd." id="logo_info_top_horizontal" />
					<img src="@/assets/images/title2.png" alt="Prive Excavating Ltd." id="logo_info_bottom" />
				</router-link>
			</div>
		</nav>
		<id-anim target="nav_links" ref="nav_links" :style="processNavLinks">
			<router-link to="/">HOME</router-link>
			<router-link to="/about">ABOUT US</router-link>
			<router-link to="/services">SERVICES</router-link>
			<router-link to="/contact">CONTACT US</router-link>
			<div id="insta_icon">
				<a href="https://www.instagram.com/priveexcavating/" target="_blank">
					<img src="@/assets/images/insta_icon.png" alt="Our Instagram" class="mobile_break" />
					<img src="@/assets/images/insta_icon_black_newest.png" alt="Our Instagram" class="desktop_break" />
				</a>
			</div>
			<span class="link_hover"></span>
			<span class="link_hover"></span>
			<span class="link_hover"></span>
			<span class="link_hover"></span>
		</id-anim>
    </div>
</template>

<script>
	import ClickIdAnim from '../anim/ClickIdAnim.vue';
	import IdAnim from '../anim/IdAnim.vue';
    import { RouterLink } from 'vue-router';
	import wait from '@/assets/js/wait.js';

    export default {
        components: {
			ClickIdAnim,
			IdAnim,
            RouterLink
        },

		data(){
			return {
				clearLoadingAnimation: false,
				loadingAnimation: undefined,
				navLinksContainer: {
					top: 0,
					height: 0
				}
			}
		},

		computed: {
			processNavLinks(){
				if(window.visualViewport.width < 1025)
					return `top: ${this.navLinksContainer.top}px; height: ${this.navLinksContainer.height}px`;
				else
					return 'top: 0';
			}
		},

		methods: {
			toggleNav(){
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
				this.$refs['nav_links'].toggle();

				let hamSlots = document.getElementsByClassName("ham_slot");
				//where javascript overrides the css, do it here
				if(this.$refs['ham_btn'].isToggled()){
					hamSlots[0].style.transform = "rotate(-45deg)";
					hamSlots[1].style.marginTop = "0";
					hamSlots[2].style.transform = "rotate(45deg)";
					hamSlots[2].style.marginTop = "14pt";
				} else {
					hamSlots[0].style.transform = "rotate(360deg)";
					hamSlots[1].style.marginTop = "13pt";
					hamSlots[2].style.transform = "rotate(360deg)";
					hamSlots[2].style.marginTop = "26pt";
				}
			}
		},

		mounted(){
			if(window.visualViewport.width < 1025){
				let hamSlots = document.getElementsByClassName("ham_slot");
				let hamSlotsDegs = [0, 120, 240];
				if(document.readyState != "complete" && document.readyState != "interactive"){
					this.loadingAnimation = window.setInterval(() => {
						for(let i = 0; i < 3; i++){
							if(!this.clearLoadingAnimation){
								hamSlotsDegs[i] += 5;
								if(hamSlotsDegs[i] >= 360)
									hamSlotsDegs[i] = 0;
							} else if(hamSlotsDegs[i] < 360){
								hamSlotsDegs[i] += 5;
							}
							
							hamSlots[i].style.transform = "rotate(" + hamSlotsDegs[i] + "deg)";
						}
						//console.log("Loading animation running.");
						if(this.clearLoadingAnimation && hamSlotsDegs[0] === 360
							&& hamSlotsDegs[1] === 360 && hamSlotsDegs[2] === 360){
							window.clearInterval(this.loadingAnimation);
							hamSlots[1].style.marginTop = "13pt";
							hamSlots[2].style.marginTop = "26pt";
							
							for(let i = 0; i < 3; i++){
								hamSlots[i].style.transition = "0.4s";
								hamSlots[i].style.height = "0";
								hamSlots[i].style.borderRadius = "2pt";
							}
						}
					}, 15);
				} else {
					hamSlots[1].style.marginTop = "13pt";
					hamSlots[2].style.marginTop = "26pt";

					for(let i = 0; i < 3; i++){
						hamSlots[i].style.transform = "rotate(360deg)";
						hamSlots[i].style.transition = "0.4s";
						hamSlots[i].style.height = "0";
						hamSlots[i].style.borderRadius = "2pt";
					}
				}
				
				this.navLinksContainer.top = document.getElementsByTagName("nav")[0].offsetHeight;
				this.navLinksContainer.height = window.visualViewport.height - this.navLinksContainer.top;
			} else {
				let navLinks = document.getElementById("nav_links").getElementsByTagName("a");
				let linkHover = document.getElementsByClassName("link_hover");
				let mouseoverTransitioning = false;
				let nav = document.getElementsByTagName("nav")[0];
				for(let i = 0; i < 4; i++){
					navLinks[i].addEventListener("mouseover", async () => {
						mouseoverTransitioning = true;
						//linkHover[i].style.top = nav.offsetHeight - 5 + "px";
						linkHover[i].style.left = navLinks[i].offsetLeft + navLinks[i].offsetWidth / 2 + "px";
						wait.millis(25, () => {
							linkHover[i].style.transition = "0.2s";
							wait.millis(25, () => {
								linkHover[i].style.width = navLinks[i].offsetWidth + "px";
								mouseoverTransitioning = false;
							});
						});
					});
					navLinks[i].addEventListener("mouseout", async () => {
						if(mouseoverTransitioning){
							wait.millis(25, () => {
								linkHover[i].style.width = "0";
								linkHover[i].style.transition = "none";
								wait.millis(25, () => {
									linkHover[i].style.width = "0";
									mouseoverTransitioning = false;
								});
							});
							return;
						}
						
						linkHover[i].style.width = "0";
						wait.millis(200, () => linkHover[i].style.transition = "0");
					});
				}
			}

			if(document.readyState === "complete" || document.readyState === "interactive")
				this.clearLoadingAnimation = true;
			else
				window.addEventListener("load", () => this.clearLoadingAnimation = true);
		},

		beforeUnmount(){

		}
    }
</script>