<template>
    <page-container id="home-page">
        <div id="titleshow">
			<div class="card">
				<div class="parallax">
					<img src="@/assets/images/excavator_parallax_newest2.jpg"
                    alt="Providing residential and commercial excavating services" />
					<div class="parallax_overlay"></div>
				</div>
			</div>
			<div class="card">
				<div class="parallax">
					<img src="@/assets/images/excavator_parallax_newest.jpg"
                    alt="Providing residential and commercial excavating services" />
					<div class="parallax_overlay"></div>
				</div>
			</div>
			<div class="card_info">
				<h1>Residential and commercial excavating services<br />&#8212;<br />Free Consultation</h1>
				<br /><br />
				<router-link to="/contact"><button>Get a Quote</button></router-link>
			</div>
			<div class="card_info">
				<h1>Providing excavating services to the
                    <br class="desktop_break" /> Lower Mainland and Fraser Valley.</h1>
				<br /><br />
				<router-link to="/contact"><button>Get a Quote</button></router-link>
			</div>
			
			<id-anim target="titleshow_arrow" ref="titleshow_arrow">&#62;</id-anim>
		</div>

        <div id="services_banner">
			<div class="service_anim" id="first_service">
				<h2>DEMOLITION</h2>
				<p>We provide our customers with an outline on how we
                    plan systematically to complete the demolition.</p>
                <hr class="banner_break mobile_break" />
			</div>
			<scroll-class-anim target="vertical_banner" :range="popupRange" id="first_banner"></scroll-class-anim>
			<div class="service_anim" id="second_service">
				<h2>EXCAVATING &#38; SHORING</h2>
				<p>We ensure safe and accurate measurements of slopes and
                    depths.</p><hr class="banner_break mobile_break" />
			</div>
			<scroll-class-anim target="vertical_banner" :range="popupRange" id="second_banner"></scroll-class-anim>
			<div class="service_anim" id="third_service">
				<h2>SITE &#38; CIVIL SERVICES</h2>
				<p>We provide a work safe environment to install underground utilities.</p>
			</div>
		</div>

        <div class="parallax" id="lparallax">
			<img src="@/assets/images/excavator_parallax.jpg"
            alt="Commercial and Residential excavating services" />
		</div>

        <div class="info">
			<h1>Satisfaction Guaranteed</h1>
			<class-anim target="seemore" ref="seemore_1" id="seemore_top">
                <p>
                    Established in 2018, Prive Excavating Ltd has been providing a wide range of residential and commercial
                    excavating services to the Lower Mainland and Fraser Valley communities. With our skilled operators and
                    equipment range, Prive Excavating Ltd guarantees completion of projects in a timely and professional manner.
                </p>
            </class-anim>
			<p id="seenomore">
				With safety and the environment in mind, Prive Excavating Ltd starts each project by implementing
				a <a href="https://www.worksafebc.com/en/health-safety" target="_blank">WorkSafe BC</a> approved
				safety plan and obtaining all city permits required to eliminate any potential risks.
            </p>
			<class-anim target="seemore" ref="seemore_2" id="seemore_bottom">
				<p>
                    With safety and the environment in mind, Prive Excavating Ltd starts each project by implementing
                    a <a href="https://www.worksafebc.com/en/health-safety" target="_blank">WorkSafe BC</a> approved
                    safety plan and obtaining all city permits required to eliminate any potential risks.
                    <b>Prive Excavating is fully bonded and has 5 million in liability insurance for our customers
                    protection.</b> Our team believes in working closely with all our customers to deliver quality work,
                    add value to our customers&#39; projects, and develop long lasting ties. Contact Us today for a free
                    quote and let us take care of all your excavation and site preparation needs.
                </p>
            </class-anim>
			<button id="seemore_btn" @click="seemore">Learn More</button>
		</div>

        <div id="services_index">
			<h1>Offering Quality Services for Your Home or Commercial Site</h1><br class="desktop_break" />
            <scroll-id-anim target="ul" :range="secondPopupRange">
                <ul>
                    <li class="li_left">Demolition</li>
                    <li>Excavation &#38; Shoring</li>
                    <li class="li_right">Trucking</li><br class="desktop_break" /><br class="desktop_break" />
                    <li class="li_left">Land Clearing</li>
                    <li>Site &#38; Civil Services</li>
                    <li class="li_right">House Lifts</li>
                </ul>
            </scroll-id-anim><br />
			<router-link to="/services"><button class="btn_black">Learn More</button></router-link>
		</div>

        <contact-profile :range="thirdPopupRange" />
    </page-container>
</template>

<script>
    import wait from '@/assets/js/wait.js';

    import ClassAnim from '@/components/anim/ClassAnim.vue';
    import ContactProfile from '@/components/structures/ContactProfile.vue';
    import IdAnim from '@/components/anim/IdAnim.vue';
    import ParallaxMixin from '@/components/mixins/ParallaxMixin.vue';
    import PageContainer from '@/components/structures/PageContainer.vue';
    import PopupRangeMixin from '@/components/mixins/PopupRangeMixin.vue';
    import { RouterLink } from 'vue-router';
    import ScrollClassAnim from '@/components/anim/ScrollClassAnim.vue';
    import ScrollIdAnim from '@/components/anim/ScrollIdAnim.vue';

    export default {
        components: {
            ClassAnim,
            ContactProfile,
            IdAnim,
            PageContainer,
            ParallaxMixin,
            RouterLink,
            ScrollClassAnim,
            ScrollIdAnim
        },

        mixins: [PopupRangeMixin, ParallaxMixin],

        methods: {
            seemore(){
                document.getElementById("seemore_btn").style.display = "none";
                document.getElementById("seenomore").style.display = "none";
                let seemores = [], seemoreDivs = document.getElementsByClassName("seemore");
                for(let i = 0; i < seemoreDivs.length; i++)
                    seemoreDivs[i].style.display = "block";
                this.$refs['seemore_1'].toggle();
                this.$refs['seemore_2'].toggle();
            }
        },

        mounted(){
            var curCard = 0;
            var titleshowCards = document.getElementById("titleshow").getElementsByClassName("card");
            var titleshowInfo = document.getElementById("titleshow").getElementsByClassName("card_info");
            var titleshowArrow = document.getElementById("titleshow_arrow");
            titleshowInfo[0].style.display = "block";

            if(window.visualViewport.width < 1025){
                titleshowArrow.style.top =
                    titleshowCards[0].offsetTop + "px";
            } else {
                titleshowArrow.style.top =
                    titleshowCards[0].offsetTop + 200 + "px";
            }
            
            for(let i = 0; i < titleshowCards.length; i++){
                titleshowInfo[i].style.marginTop = "30pt";
                titleshowInfo[i].style.top = titleshowCards[0].offsetTop +
                    titleshowCards[0].offsetHeight / 2 + "px";
                titleshowInfo[i].style.opacity = "1.0";
            }

            if(window.visualViewport.width < 1025)
                titleshowArrow.style.top =
                    titleshowCards[0].offsetTop + "px";
            else
                titleshowArrow.style.top =
                    titleshowCards[0].offsetTop + 200 + "px";
            
            for(let i = 0; i < 2; i++){
                this.parallaxes[i].setPositionStemCoord(1, 50);
                this.parallaxes[i].requestTranslate();
            }
            
            for(let i = 1; i < titleshowCards.length; i++){
                titleshowCards[i].style.top = document.getElementsByTagName("nav")[0].offsetHeight + "px";
                titleshowInfo[i].style.opacity = "0.0";
            }
            
            window.addEventListener("resize", () => {
                if(window.visualViewport.width < 1025){
                    titleshowArrow.style.top =
                        titleshowCards[0].offsetTop + "px";
                } else {
                    titleshowArrow.style.top =
                        titleshowCards[0].offsetTop + 200 + "px";
                }
            });

            function titleshowSwap(){
                if(++curCard >= titleshowCards.length)
                    curCard = 0;
                
                titleshowInfo[curCard].style.display = "block";
                for(let i = 0; i < titleshowCards.length; i++){
                    if(i !== curCard){
                        titleshowCards[i].style.opacity = "0.0";
                        titleshowInfo[i].style.opacity = "0.0";
                    }
                }
                wait.millis(25, () => {
                    titleshowCards[curCard].style.opacity = "1.0";
                    titleshowInfo[curCard].style.opacity = "1.0";
                    wait.millis(375, () => {
                        for(let i = 0; i < titleshowCards.length; i++){
                            if(i !== curCard)
                                titleshowInfo[i].style.display = "none"; 
                        }
                    });
                });
            }

            let titleshowTick = 0;
            let titleshowArrowDiv = document.getElementById("titleshow_arrow");
            if(!titleshowArrowDiv)
                titleshowArrowDiv = document.getElementById("titleshow_arrow_anim");
            titleshowArrowDiv.addEventListener("click", () => {
                titleshowTick = 0;
                titleshowSwap();
            });

            this.$refs['titleshow_arrow'].toggle();
            let titleshowInterval = window.setInterval(() => {
                if(++titleshowTick >= 8){
                    titleshowTick = 0;
                    titleshowSwap();
                }
            }, 500);
        }
    }
</script>

<style>
    #titleshow .card img{
        width: 100%;
    }

    #titleshow .card:first-child{
        opacity: 1.0;
        position: static;
    }

    #titleshow .card{
        transition: 0.4s;
        opacity: 0.0;
        position: absolute;
        width: calc(100%);
    }

    /*#titleshow .card_info span{
        font-family: Roboto Slab, Tahoma, Arial, Candara, "Dejavu Sans";
        font-size: 14pt;
    }*/

    #titleshow .card .parallax{
        height: 350pt;
        background-size: 100% auto;
    }

    #titleshow .card_info{
        transition: 0.4s;
        display: none;
        margin-left: 5%;
        width: 90%;
        text-align: center;
        color: white;
        transform: translateY(-55%);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        opacity: 0.0;
    }

    #titleshow_arrow{
        transition: 0.4s;
        display: none;
    }

    #titleshow_arrow, #titleshow_arrow_anim{
        transition: 0.4s;
        font-family: Impact, Arial;
        font-size: 30pt;
        font-weight: 900;
        width: 35pt;
        height: 35pt;
        background: rgba(18, 26, 67, 0.75);
        color: white;
        text-align: center;
        position: absolute;
        right: 0;
        cursor: pointer;
        border: solid white 1pt;
    }

    #services_banner, #services_index{
        background-color: #121A43;
        color: white;
        font-family: Inter, Calibri, Arial, Tahoma;
        padding: 25pt;
    }

    #services_banner h2, #services_index h2{
        font-weight: 400;
        font-family: Roboto Slab, Calibri, Arial, Tahoma;
    }

    #services_banner p{
        font-family: Inter, Calibri, Arial, Tahoma;
    }

    #services_banner{
        text-align: center;
        border-top: solid white 1pt;
        border-bottom: solid white 1pt;
    }

    .banner_break{
        transition: 0.4s;
        width: 0%;
        height: 1pt;
        border: 0;
        background-color: white;
    }

    #services_banner .service, #services_banner .service_anim{
        transition: 0.4s;
        opacity: 0.0;
    }

    #services_banner .service_anim{
        opacity: 1.0;
    }

    #services_banner .service_anim .banner_break{
        width: 60%;
    }

    #ul, #ul_anim{
        transition: 0.4s;
    }

    #ul{
        opacity: 0.0;
    }

    #ul_anim{
        opacity: 1.0;
    }

    .seemore, .seemore_anim{
        transition: 0.4s;
        opacity: 0.0;
        display: none;
    }

    .seemore_anim{
        opacity: 1.0;
    }

    #lparallax{
        background-size: cover;
    }

    @media only screen and (min-width: 732px){
        #services_banner .service_anim .banner_break{
            width: 45%;
        }
    }

    @media only screen and (orientation: landscape){
        #titleshow_arrow, #titleshow_arrow_anim{
            transition: 0.4s;
            font-family: Impact, Arial;
            font-size: 40pt;
            font-weight: 900;
            width: 50pt;
            height: 50pt;
            background: rgba(18, 26, 67, 0.75);
            color: white;
            text-align: center;
            position: absolute;
            right: 0;
        }
        
        #services_banner .service_anim .banner_break{
            width: 30%;
        }
    }

    @media only screen and (min-width: 1025px) and (orientation: landscape){
        #titleshow_arrow:hover, #titleshow_arrow:active,
            #titleshow_arrow_anim:hover, #titleshow_arrow_anim:active{
            transition: 0.4s;
            background-color: #121A43;
            border: solid #E5D600 1pt;
            color: #E5D600;
        }
        
        #titleshow .card_info{
            font-size: 16pt;
        }
        
        #services_banner{
            text-align: left;
        }
        
        #services_banner p{
            font-size: 12pt;
        }
        
        #services_banner h2{
            font-size: 16pt;
            font-weight: 900;
        }
        
        .service p, .service_anim p{
            width: 225pt;
        }
        
        #first_service{
            margin: -28pt -25pt -25pt calc(33.33333% - 250pt);/*position of vertical banner subtract width and whatever padding needed*/
        }
        
        #second_service, #third_service{
            position: absolute;
            margin-top: -78pt;
        }
        
        #second_service{
            left: calc(50% - 112.5pt);
        }
        
        #third_service{
            left: calc(66.66667% + 25pt);/*position of vertical banner plus padding*/
        }
        
        .vertical_banner, .vertical_banner_anim{
            transition: 0.4s;
            height: 0;
            width: 2pt;
            background-color: white;
            display: block;
            position: absolute;
            transform: translateY(-50%);
            margin-top: -26pt;
        }
        
        #first_banner{
            left: 33.33333%;
        }
        
        #second_banner{
            left: calc(66.66666% - 25pt);
        }
        
        .vertical_banner_anim{
            transition: 0.4s;
            height: 70pt;
        }
        
        #services_index{
            text-align: center;
            font-size: 12pt;
        }
        
        #services_index li{
            font-size: 18pt;
        }
        
        #services_index .li_left, #services_index .li_right{
            position: absolute;
            margin: 0;
        }
        
        #services_index .li_left{
            left: calc(50% - 335pt);
        }
        
        #services_index .li_right{
            right: calc(50% - 335pt);
        }
        
        ul{
            list-style: none;
            margin-left: -31pt;
        }
        
        li{
            font-family: Roboto Slab, Arial;
            display: inline;
        }
    }
</style>