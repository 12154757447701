<script>
    class Parallax{
        //assumes an element with its src image inside
        constructor(el){
            this.el = el;
            this.img = this.el.getElementsByTagName("img")[0];
            this.translateMod = 0.0025;
            this.rangeAbove = 800;
            this.positionStem = [50, 28];
        }
        
        activate(){
            this.el.style.backgroundImage = "url('" + this.img.src + "')";
            this.img.style.display = "none";
        }
        
        setPositionStem(positionStem){
            this.positionStem = positionStem;
        }
        
        setPositionStemCoord(index, positionStem){
            this.positionStem[index] = positionStem;
        }
        
        requestTranslate(){
            if(window.scrollY >= this.el.offsetTop - this.rangeAbove){
                let translateY = window.scrollY - this.el.offsetTop;
                this.el.style.backgroundPosition = this.positionStem[0] + "% "
                    + (this.positionStem[1] + (translateY * this.translateMod)) + "%";
            }
        }
        
        setTranslateMod(translateMod){
            this.translateMod = translateMod;
        }
        
        setRangeAbove(rangeAbove){
            this.rangeAbove = rangeAbove;
        }
    }

    export default {
        data(){
            return {
                parallaxes: []
            }
        },

        methods: {
            updateParallaxes(){
                for(let i = 0; i < this.parallaxes.length; i++)
			        this.parallaxes[i].requestTranslate();
            },

            sizeParallaxes(){
                let parallaxElements = document.getElementsByClassName("parallax");
                this.parallaxes = [];
                for(let i = 0; i < parallaxElements.length; i++){
                    this.parallaxes.push(new Parallax(parallaxElements[i]));
                    this.parallaxes[i].activate();
                    if(parallaxElements[i].getElementsByClassName("parallax_overlay")[0]){
                        /*parallaxElements[i].getElementsByClassName("parallax_overlay")[0].style.height
                            = "calc(" + parallaxElements[i].offsetHeight + "px - 1pt)";*/
                        if(i === 0)
                            parallaxElements[i].getElementsByClassName("parallax_overlay")[0].style.height
                                = "calc(" + parallaxElements[i].offsetHeight + "px - 1pt)";
                    }
                }
            },

            installParallaxes(){
                this.sizeParallaxes();
                this.updateParallaxes();
            }
        },

        mounted(){
            window.addEventListener("scroll", this.updateParallaxes);
            this.installParallaxes();
        },

        beforeUnmount(){
            window.removeEventListener("scroll", this.updateParallaxes);
        }
    }
</script>