import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/about',
		name: 'about',
		component: () => {
			return import ('../views/About.vue');
		}
	},
	{
		path: '/services',
		name: 'services',
		component: () => {
			return import ('../views/Services.vue');
		}
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => {
			return import ('../views/Contact.vue');
		}
	},
	/*
	{
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: function () {
			return import('../views/AboutView.vue');
		}
	}
	*/
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
