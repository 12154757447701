<template>
    <footer>
        <div>&#169; Copyright 2023 | Prive Excavating Ltd | All Rights Reserved</div><br class="mobile_break" />
        <a href="https://www.intivatechnologies.ca" target="_blank" id="intiva_link_desktop">
            <hover-id-anim target="calling_card">
                Website powered by
                <img src="@/assets/images/intiva.png" alt="Intiva Technologies" id="intiva_icon" />
                <img src="@/assets/images/intiva_hovered.png" alt="Intiva Technologies" id="intiva_icon_highlighted" />
            </hover-id-anim>
        </a>
        <div id="intiva_link">
            Website powered by<br />
            <a href="https://www.intivatechnologies.ca" target="_blank">
                <img src="@/assets/images/intiva_mobile.png" alt="Intiva Technologies" />
            </a>
        </div>
    </footer>
</template>

<script>
    import HoverIdAnim from '../anim/HoverIdAnim.vue';

    export default {
        components: {
            HoverIdAnim
        }
    }
</script>